import React from 'react';
import axios from 'axios';
import BlogTitle from '../BlogTitle';
import BlogDescription from '../BlogDescription';

class PostPage  extends React.PureComponent{

    constructor(props){
        super(props);
        this.state={
            posts:{},
            isLoaded:false
        }
    }

    componentDidMount(){
        axios
        .get(`https://lovehacks.info/wp-json/wp/v2/posts`)
        .then(response => {
            const data = response.data;
            this.setState({posts:data, isLoaded: true});
        })
        .catch(error => {
    
        });
      }
    render(){
        const { posts, isLoaded } = this.state;
        if(!isLoaded){
            return <div>Loading</div>
        }
        return(
            posts.map((post, index) => {
                console.log(post);
                return (
                    <React.Fragment key={`fragment${index}`}>
                <BlogTitle key={`title${index}`} title={post.title.rendered} /> 
                <BlogDescription key={`description${index}`} excerpt={post.excerpt.rendered} />
                </React.Fragment>
                );
            })
        
        );
    }
    
}

export default PostPage;