import React, { Component } from 'react';

import {Experiment, Variant} from 'react-ab-experiment'

import EoiConnect from './EoiConnect';
import EoiInterest from './EoiInterest';

const LocalStorageCache  = {
  get:  (key) => {
    return window.localStorage.getItem(key)
  },
  set: (key, value) => {
    return window.localStorage.setItem(key, value)
  }
}

class Eoi extends Component {
  
  handleEnrolment (experimentId, variantName) {
    // send enrollment data to AB test reporting tool, eg: Google Analytics 
    console.log("sasa",experimentId,variantName);
  }

  fetchVariantName2 (experimentId) {
    return fetch(`https://my-ab-test-server.com/experiments/${experimentId}/variant`, {
      credentials: 'include' })
      .then(response => response.json())
      .then(response => response.variant)
  }

  fetchVariantName (experimentId) {

    console.log('experimentId',experimentId);
    return fetch(`http://localhost:3000/getVariant/index.php`, {
      credentials: 'include' })
      .then(response => response.json())
      .then(response => response.variant)
  }

  components = {
        EoiConnect: EoiConnect,
        EoiInterest: EoiInterest
    };
    

  render() {
    const TagName = this.components[this.props.renderComp || 'EoiConnect'];
    return (  //this.props.renderComp
        
        //return <TagName />
      	<div>	
        {TagName}
        {this.props.bucket}          
        	<Experiment id="testExperiment" onEnrolment={this.handleEnrolment} cache={LocalStorageCache}>
		        <Variant name="A">
		          <div>AAAA<TagName  />BB {this.props.name} </div> 
		        </Variant>
		        <Variant name="B">
            <div>BB<EoiInterest /> {this.props.name} </div> 		          
		        </Variant>
		        <Variant name="C">
		          <div>Variant 2 {this.props.name}</div>
		        </Variant>
        }
	      </Experiment>      
    	</div>
     )
  }
}

export default Eoi;
