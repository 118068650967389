import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

//Domain List
import DomainList from './DomainList';

//Domain Pages
import OneIdiot from './DomainPages/OneIdiot';
import DevBox from './DomainPages/DevBox';
import DomainSale from './DomainPages/DomainSale';
import ComingSoon from './DomainPages/ComingSoon';
import FullStack from './DomainPages/FullStack';
import DialoguesClub from './DomainPages/DialoguesClub';

//DevBox Pages
// import Home from './pages/Home';
import About from './DomainPages/DevBox/InnerPages/About/index';
import Contact from './DomainPages/DevBox/InnerPages/Contact';
import Resources from './DomainPages/DevBox/InnerPages/Resources';
import Code from './DomainPages/DevBox/InnerPages/Code';
//DevBox Tutorials
import ExerciseTracker from './DomainPages/DevBox/Tutorials/ExerciseTracker';
import Todo from './DomainPages/DevBox/Tutorials/ToDoApp';
import SimpleWeatherApp from './DomainPages/DevBox/Tutorials/SimpleWeatherApp';
import DomainApp from './DomainPages/DevBox/Tutorials/DomainApp';
import MovieApp from './DomainPages/DevBox/Tutorials/MovieApp';
import BasicToDoApp from './DomainPages/SampleCode/BasicToDoApp/App';

function ShowDomain(){
  const hostname = window.location.hostname;
  const domainsale = DomainList();
  const displaycomponent = domainsale.filter((value) => {if(value.domain === hostname){return value;} return null; });
  return (displaycomponent && displaycomponent[0] && displaycomponent[0].renderComponent) || ComingSoon;
}

const HomePageToRender = ShowDomain();

function App() {
  return (
    <>
    <Router>
        <Switch>
          <Route path="/" exact><HomePageToRender /></Route>
          <Route path="/oneidiot" exact><OneIdiot /></Route>
          <Route path="/devbox" exact><DevBox /></Route>
          <Route path="/sale" exact><DomainSale /></Route>
          <Route path="/about" exact><About /></Route>
          <Route path="/contact" exact><Contact /></Route>
          <Route path="/code" exact><Code /></Route>
          <Route path="/resources" exact><Resources /></Route>
          <Route path="/code/to-do" exact><Todo /></Route>
          <Route path="/basic-todo" exact><BasicToDoApp /></Route>
          <Route path="/code/simple-weather-app" exact><SimpleWeatherApp /></Route>
          <Route path="/code/domain-app" exact><DomainApp /></Route>
          <Route path="/code/movie-app" exact><MovieApp /></Route>
          <Route path="/code/dialogues-club" exact><DialoguesClub /></Route>
          <Route path="/code/project-app" exact><FullStack /></Route>
          <Route path="/code/exercise-tracker" exact><ExerciseTracker /></Route>
          <Route path="/code/exercise-tracker/create" exact><ExerciseTracker /></Route>
          <Route path="/code/exercise-tracker/edit/:id" exact><ExerciseTracker /></Route>
          <Route path="/code/exercise-tracker/user" exact><ExerciseTracker /></Route>
         </Switch>
    </Router>
    </>
  );
}

export default App;
