import React from 'react';
// import ComingSoon from 'react-coming-soon'

export default function ComingSoonPage(){
    return(
      <>

      Coming Soon
      {/* <ComingSoon 
        title="Coming Soon"
        image="https://react-coming-soon.maksv.me/default-image.jpeg"
        bgColor="#abb8c3" 
        textColor="#eb144c" 
        date="Thu Dec 31 2023 00:00:00 GMT+0530 (India Standard Time)" 
        illustration="web-development" 
        children={"Contact me - hello@devbox.in"}/> */}
      <p></p>
      </>
    );
}
