import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import PostPage from './components/PostPage';


class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      title: '',
      description: '',
    }
    console.log('Start');
  }

  componentDidMount(){
    axios
    .get(`https://lovehacks.info/wp-json`)
    .then(response => {
      const data = response.data;
      this.setState({
            title: data.name,
            description: data.description,
        });
      document.title = data.name;
    })
    .catch(error => {

    });

  }



  render() {
    if(!this.state.title){
      return <div className="App"><h1 className="App-title">Loading...</h1></div>;
    }
    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">{this.state.title}</h1>
          <h5 >{this.state.description}</h5>
        </header>
        <div className="App-intro">
          <PostPage />
        </div>
      </div>
    );
  }
}

export default App;
