import React from 'react';

export default function Home(){
    return (
        <React.Fragment>
            <div className="jumbotron text-center">
                <h1>Welcome to DevBox.in</h1>
                {/* <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small> */}
                <p>One stop to start learning Development</p>
            </div>
        </React.Fragment>
    );
}
