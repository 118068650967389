import React from 'react';
import Tech from './Tech';
import Info from './Info';
// import Footer from './Footer';
import Navbar from '../../PartialPages/NavbarPartial';

export default function About(){
    
    return (
        <React.Fragment>
        <div className="container">
            <Navbar />
            <div className="row">
                <div className="col"><Info /></div>
            </div>
            <div className="row">
                <div className="col"><Tech /></div>
            </div>
        </div>
        </React.Fragment>
    );

}