import React from 'react';
import Navbar from '../PartialPages/NavbarPartial';

export default function Resources(){

    return(
        <React.Fragment>
            <div className="container">
            <Navbar />
            <h3>Development Resources</h3>
            <ul>
                <li><a className="App-link" href="https://jsfiddle.net/user/saaheel/fiddles/" target="_blank" rel="noopener noreferrer">JSFiddle</a></li>
                <li><a className="App-link" href="https://codesandbox.io/u/saaheel" target="_blank" rel="noopener noreferrer">CodeSandBox</a></li>
                <li><a className="App-link" href="https://codepen.io/saaheel" target="_blank" rel="noopener noreferrer">CodePen.io</a></li>
                <li><a className="App-link" href="https://www.codechef.com/users/saaheel" target="_blank" rel="noopener noreferrer">CodeChef.com</a></li>
            </ul>
            <h3>LAMP Stack - PHP MySQL</h3>
            <ul>
                <li><a className="App-link" href="https://code.phpcoaching.in" target="_blank" rel="noopener noreferrer">PHP Code Snippets</a></li>
                <li><a className="App-link" href="https://krazypost.com" target="_blank" rel="noopener noreferrer">KrazyPost - WordPress Blog</a></li>
                <li><a className="App-link" href="https://smscollection.in" target="_blank" rel="noopener noreferrer">SmsCollection - WordPress Blog</a></li>
                <li><a className="App-link" href="https://wp.devbox.in" target="_blank" rel="noopener noreferrer">Testing - WordPress Blog</a></li>
            </ul>
            <h3>JavaScript - ES6</h3>
            <ul>
                <li><a className="App-link" href="https://wpfun.devbox.in/" target="_blank" rel="noopener noreferrer">Static WordPress Site</a></li>
            </ul>
            <h3>Node JS</h3>
            <ul>
                <li>Coming Soon</li>
            </ul>
            <h3>React JS</h3>
            <ul>
                <li><a className="App-link" href="https://wpreact.devbox.in/" target="_blank" rel="noopener noreferrer">React Frontend using WP API</a></li>
                <li><a className="App-link" href="https://reactab.devbox.in/" target="_blank" rel="noopener noreferrer">React AB Testing</a></li>
            </ul>
            <h3>Tech Roadmap for year {new Date().getFullYear()}</h3>
            <ul className="navbar-nav mr-auto">
                <li>Coming Soon</li>
            </ul>
            </div>
            
        </React.Fragment>
    );
}