import { combineReducers } from 'redux';
import todos from './todos';
import tictactoe from './tictactoe';
import settings from './settings';

const todoApp = combineReducers({
  todos,
  tictactoe,
  settings,
});

export default todoApp;
