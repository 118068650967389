import styled from 'styled-components';
const styles = {};

styles.ToDoListContainer = styled.div`
	align-items: center;	InputListContainer
	background:#e5e7e9;
	width:100%
`;

styles.Header = styled.div`
	text-align: center; 
`;

styles.InputListContainer = styled.div`
	align-items: center;	
	background:#e5e7e9;
	width:100%
`;

styles.ShowListContainer = styled.div`
	align-items: center;	
	background:#e5e7e9;
	width:100%
`;

styles.List = styled.ul`
	padding: 0;	
`;
styles.ListItem = styled.li`
	background-color: #efefef;	
`;

export default styles;
