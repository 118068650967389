import React from 'react';
import {Helmet} from "react-helmet";

export default function OneIdiot(){

    return(
      <>
      <Helmet>
          <title>One Idiot - SIP Karo Khush Raho - Mast Raho</title>
      </Helmet>
      <div className="jumbotron text-center">
        <p>I am an idiot...Follow me...!!!</p>
        <p>Be Wealthy... Be Wise</p>
        <iframe title="oneidiotmovie" width="560" height="315" src="https://www.youtube-nocookie.com/embed/vU1l1TB7GzI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
        <p>Get Financial Health Calculator</p>
      </div>
      <div className="container">
        <div className="row">
        <div className="col-sm-4">
          <h3>Earn Money</h3>
          <p>Primary Source - Salary / Business</p>
          <p>Secondary Source - Interest / Rent</p>
        </div>
        <div className="col-sm-4">
          <h3>Save Money</h3>
          <p>Create RD / FD</p>
          <p>Save in PPF Account</p>
        </div>
        <div className="col-sm-4">
          <h3>Invest Money</h3>
          <p>Invest in Equities</p>
          <p>Invest in Mutual Fund</p>
        </div>
        </div>
      </div>
      </>
    );

}