import styled from 'styled-components';
const styles = {};

styles.TicTacToeContainer = styled.div`
	align-items: center;	
	background:#e5e7e9;
	width:100%
`;

styles.BoardContainer = styled.div`
	align-items: center;		
	width:100%
`;

styles.game = styled.div`
  display: flex;
  flex-direction: row;
`;

styles.game_board = styled.div`
  margin-left: 520px;
`;

export default styles;
