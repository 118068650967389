import React from 'react';

export default function DomainSale(){
    return(
      <div className="container">
        <p>
          Domain for sale. Contact hello@devbox.in
        </p>
      </div>
    );
}