import React, { Component } from 'react';
import Square from './Square';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import s from './style';

class Board extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props);

    this.renderSquare = this.renderSquare.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.confirmMove = this.confirmMove.bind(this);
    this.handleReset = this.handleReset.bind(this);

    // console.log(this.props.settings);

    // local state
    this.state = { '0': 'X', '1': 'O' };
    // console.log(this.state[0])
  }

  componentDidMount() {
    // console.log(this.props.settings);

    if (this.props.settings[0]) {
      // console.log("zerop");
      this.setState({
        '0': this.props.settings[0].name,
      });
    }

    if (this.props.settings[1]) {
      // console.log("zerop");
      this.setState({
        '1': this.props.settings[1].name,
      });
    }
  }

  handleClick(i) {
    this.props.addMove(i);
  }

  handleReset() {
    this.props.reset_move();
  }

  confirmMove() {
    this.props.confirmMove();
  }

  renderSquare(i) {
    return <Square value={this.props.tictactoe.squares[i]} onClick={() => this.handleClick(i)} />;
  }

  render() {
    const status = `Next player: ${this.props.tictactoe.xIsNext ? this.state[0] : this.state[1]}`;

    return (
      <s.BoardContainer>
        <div className="status">{status}</div>
        <div className="board-row">
          {this.renderSquare(0)}
          {this.renderSquare(1)}
          {this.renderSquare(2)}
        </div>
        <div className="board-row">
          {this.renderSquare(3)}
          {this.renderSquare(4)}
          {this.renderSquare(5)}
        </div>
        <div className="board-row">
          {this.renderSquare(6)}
          {this.renderSquare(7)}
          {this.renderSquare(8)}
        </div>

        <button onClick={() => this.confirmMove()}>Done</button>
        <button onClick={() => this.handleReset()}>Reset</button>

      </s.BoardContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Board);
