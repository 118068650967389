import React from 'react';
import Navbar from '../PartialPages/NavbarPartial';

export default function Contact(){
    return (
        <React.Fragment>
            <div className="container">
                <Navbar />
                <div className="row">
                    <div className="col">
                        <h3>mail me - hello@devbox.in</h3>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    );
}