import React, { Component } from 'react';
import { connect } from 'react-redux';
import s from './style';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const currentTime = new Date();
    this.state = {
      time: `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`,
    };
    this.startTimer = this.startTimer.bind(this);
  }

  startTimer() {
    this.interval = setInterval(() => {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const seconds = currentTime.getSeconds();
      const minutes = currentTime.getMinutes();
      const time = `${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds }`;
      this.setState({ time });
    }, 1000);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      <s.SideBarContainer>
        <s.Header>Dashboard of My Project</s.Header>
        <s.Main>Current Time : {this.state.time}</s.Main>
      </s.SideBarContainer>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Dashboard);
