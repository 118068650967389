const initialPlayers = [];

const settings = (state = initialPlayers, action) => {
  switch (action.type) {
    case 'ADD_PERSON': {
      console.log(state);
      return [...state, action.data];
    }

    default:
      return state;
  }
};

export default settings;
