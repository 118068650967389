let nextTodoId_0 = 0;
export const addTodo_0 = text => ({
  type: 'ADD_TODO_0',
  id: nextTodoId_0++,
  text,
});

let nextTodoId_1 = 0;
export const addTodo_1 = text => ({
  type: 'ADD_TODO_1',
  id: nextTodoId_1++,
  text,
});

export const addMove = index => ({
  type: 'add_xo',
  id: index,
});

export const confirmMove = () => ({
  type: 'confirm_move',
});

export const reset_move = () => ({
  type: 'reset_move',
});

let personId = 0;
export const addPerson = data => ({
  type: 'ADD_PERSON',
  id: personId++,
  data,
});
