import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addTodo_0, addTodo_1 } from '../../actions';

class InputList extends Component {
  constructor(props) {
    super(props);
    this.addTodoItem = this.addTodoItem.bind(this);
  }

  addTodoItem(index) {
    // console.log(index);
    // console.log("on click of add addTodoItem");
    const label_0 = this.refs.label_0.value;
    if (label_0) {
      this.props.addTodo_0(label_0);
    }
    const label_1 = this.refs.label_1.value;
    if (label_1) {
      this.props.addTodo_1(label_1);
    }

    // reset the text value
    this.refs.label_0.value = '';
    this.refs.label_1.value = '';
  }

  render() {
    return (
      <div>
        <input type="text" ref="label_0" />
        <button
          onClick={() => {
            this.addTodoItem(0);
          }}
        >
          Add person 1
        </button>

        <input type="text" ref="label_1" />
        <button
          onClick={() => {
            this.addTodoItem(1);
          }}
        >
          Add person 2
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ addTodo_0, addTodo_1 }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(InputList);
