import React from 'react';
import Gravatar from 'react-gravatar';
// import SocialWidget from './SocialWidget';

function Info(){
    return (
        <React.Fragment>
        <h2>I am Saaheel Majethia</h2>
        <Gravatar email="hello@devbox.in" size={250} />
        <p>Follow Me</p>
        {/* <SocialWidget /> */}
        </React.Fragment>
    );
}

export default Info;