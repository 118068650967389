import React from 'react';

export default function FullStack(){
    return(
        <>
        <div className="container bg-light">
            <h1>Project Ideas</h1>
            <ul className="list-group">
                <li className="list-group-item bg-info">
                    Tic Tac Toe
                    <p>
                        <span class="badge badge-pill badge-primary">ReactJS</span>&nbsp;
                    </p>
                </li>
                <li className="list-group-item">
                    Content Management System
                    <p>
                        <span class="badge badge-pill badge-primary">MERN Stack</span>&nbsp;
                        <span class="badge badge-pill badge-primary">ReactJS</span>&nbsp;
                    </p>
                </li>
                <li className="list-group-item">
                    Todo List
                    <p>
                        <span class="badge badge-pill badge-primary">Vanilla JavaScript</span>&nbsp;
                        <span class="badge badge-pill badge-primary">ReactJS</span>&nbsp;
                    </p>
                </li>
            </ul>
        </div>
        </>
    );
}