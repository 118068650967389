import React from 'react';
import Header from './PartialPages/HeaderPartial';
import Navbar from './PartialPages/NavbarPartial';
import Footer from './PartialPages/FooterPartial';
import Home from './InnerPages/Home';
import {Helmet} from "react-helmet";
// import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
// import About from './InnerPages/About/index';
export default function DevBox(){

    return(
        <>
        <Helmet>
            <title>Saaheel Majethia DevBox</title>
            <meta
                name="description"
                content="Saaheel Majethia-Tech Consultant from Bangalore | Working with WordPress, JavaScript, React JS, Node JS"
            />
        </Helmet>
        <div className="container">
            <Header />
            <Navbar />
            <Home />
            <Footer />
        </div>
        
        </>
    );

}