import React from 'react';
//import { connect } from 'react-redux';
import InputList from './InputList';
import ShowList from './ShowList';
//import { addTodo } from './actions'



const App = () => 
 (
      <div className="App">
        <div className="">
          <h2>Welcome to React To Do List</h2>
        </div>
        <div className="App-intro">
          <InputList />
        </div>
        <div className="App-intro">
          <ShowList  />
        </div>
      </div>
    );
  


export default App;
