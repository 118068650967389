import styled from 'styled-components';
const styles = {};

styles.SideBarContainer = styled.div`
	align-items: center;	
	font-weight: bold;
	background:#e5e7e9;
	width:100%
`;

styles.Header = styled.div`
	background: lightgrey;
	flex :1 100%;
	padding:10px;
	text-align: center; 
`;

styles.Main = styled.div`
	padding:10px;
	flex:5; 
`;
export default styles;
