import React, { Component } from 'react';
import { connect } from 'react-redux';
import s from './style';

class ShowList extends Component {
  // why constructor is useless here???

  constructor(props) {
    super(props);

    // local state
    this.state = { '0': 'Person 1', '1': 'Person 2' };

    // console.log(this.state[0]);
  }

  componentDidMount() {
    // console.log(this.props.settings);

    if (this.props.settings[0]) {
      // console.log("zerop");
      this.setState({
        '0': this.props.settings[0].name,
      });
    }

    if (this.props.settings[1]) {
      // console.log("zerop");
      this.setState({
        '1': this.props.settings[1].name,
      });
    }
  }

  render() {
    return (
      <div>
        <h3>To Do List of {this.state[0]}</h3>
        <s.List>
          {this.props.todos.todo_0.map((toDoItem, index) => <s.ListItem key={index}>{toDoItem.text}</s.ListItem>)}
        </s.List>
        <h3>To Do List of {this.state[1]}</h3>
        <s.List>
          {this.props.todos.todo_1.map((toDoItem, index) => <s.ListItem key={index}>{toDoItem.text}</s.ListItem>)}
        </s.List>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ShowList);
