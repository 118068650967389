import React from 'react';

export default function MovieApp(){
    return (
        <>
            <div className="container">
                <h2>Movie App</h2>
            </div>

        </>
    );
}