import styled from 'styled-components';
const styles = {};

styles.SideBarContainer = styled.div`
	align-items: center;	
	font-weight: bold;
	background:#e5e7e9;
	width:100%
`;

styles.Header = styled.div`
	background: lightgrey;
	flex :1 100%;
	padding:10px;
	font-weight: bold;
	text-align: center; 
`;

styles.Main = styled.div`
	padding:10px;
	flex:5; 
`;

styles.SideBarNav = styled.div`
	background: #44fefe;
	padding:10px;	
 
`;

styles.List = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	justify-content: space-around;
`;
styles.ListItem = styled.li`
	background-color: #00ff00;
	padding: 5px;
	width: 100px;
	height: 15px;
	margin-top: 10px;
	line-height: 15px;
	color: white;
	font-size: 1em;		
	text-align: center;
`;

export default styles;
