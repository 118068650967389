import React, { Component } from 'react';
import { connect } from 'react-redux';
import Eoi from '../../components/Eoi';

class Abpage extends Component {
  
  handleEnrolment (experimentId, variantName) {
    // send enrollment data to AB test reporting tool, eg: Google Analytics 
    console.log("sasa",experimentId,variantName);
  }

  render() {
    return (
      <div>    
        <Eoi name="connect" bucket="A" renderComp="EoiConnect" />      
      </div>
     )
  }
}

function mapStateToProps(state) {
  return state;
}



export default connect(mapStateToProps)(Abpage);
