import React from 'react';

export default function IIDC(){

    return (
        <>
        <div class="container">
            <h3>IIDC - Income Ideas During COVID</h3>
            <ul>
                <li>Start Freelance Consultancy</li>
                <li>Build E-Commerce App</li>
            </ul>
        </div>
        
        </>
    );
}