import OneIdiot from './DomainPages/OneIdiot';
import DevBox from './DomainPages/DevBox';
import DomainSale from './DomainPages/DomainSale';
import ComingSoon from './DomainPages/ComingSoon';
import CodeAndCoffee from './DomainPages/CodeAndCoffee';
import IIDC from './DomainPages/IIDC';
import FullStack from './DomainPages/FullStack';
import DialoguesClub from './DomainPages/DialoguesClub';
import BasicToDoApp from './DomainPages/SampleCode/BasicToDoApp/App';
import ReduxToDoApp from './DomainPages/SampleCode/ReduxToDoApp/App';
import CombinedToDoTicTacToe from './DomainPages/SampleCode/CombinedToDoTicTacToe/App';
import WPApi from './DomainPages/SampleCode/WPApi/App'

const saleText = 'Domain for sale. Contact hello@devbox.in';

const DomainListDetails = [
  {'domain':'localhost','visible':true,'text':saleText,'renderComponent':ComingSoon},
  {'domain':'wpcli.org','visible':true,'text':saleText,'renderComponent':WPApi},
  {'domain':'sahil.be','visible':true,'text':saleText,'renderComponent':CombinedToDoTicTacToe},
  {'domain':'localhost3','visible':true,'text':saleText,'renderComponent':ReduxToDoApp},
  {'domain':'localhost2','visible':true,'text':saleText,'renderComponent':BasicToDoApp},
  {'domain':'localhost1','visible':true,'text':saleText,'renderComponent':DomainSale},
  {'domain':'devbox.in','visible':false,'text':saleText,'renderComponent':DevBox},
  {'domain':'reactapp.devbox.in','visible':false,'text':saleText,'renderComponent':DevBox},
  {'domain':'oneidiot.me','visible':false,'text':saleText,'renderComponent':OneIdiot},
  {'domain':'oneidiot.netlify.app','visible':false,'text':saleText,'renderComponent':OneIdiot},
  {'domain':'bewealthy.in','visible':true,'text':saleText,'renderComponent':DomainSale},
  {'domain':'sipkaro.net','visible':true,'text':saleText,'renderComponent':DomainSale},
  {'domain':'iidc.in','visible':false,'text':saleText,'renderComponent':IIDC},
  {'domain':'designcompany.in','visible':true,'text':saleText,'renderComponent':DomainSale},
  {'domain':'sparkupweb.com','visible':true,'text':saleText,'renderComponent':DevBox},
  {'domain':'justlearn.in','visible':true,'text':saleText,'renderComponent':ComingSoon},
  {'domain':'c4c.dev','visible':true,'text':saleText,'renderComponent':CodeAndCoffee},
  {'domain':'fullstack.co.in','visible':true,'text':saleText,'renderComponent':FullStack},
  {'domain':'dialogues.club','visible':true,'text':saleText,'renderComponent':DialoguesClub},
];

export default function DomainList(){
  return DomainListDetails;
}