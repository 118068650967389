import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";

import Navbar from "./components/navbar.component"
import ExercisesList from "./components/exercises-list.component";
import EditExercise from "./components/edit-exercise.component";
import CreateExercise from "./components/create-exercise.component";
import CreateUser from "./components/create-user.component";


export default function ExerciseTracker(){

    return(
        <React.Fragment>
        <h3>Exercise Tracker</h3>
        <Router>
        <div className="container">
        <Navbar />
        <br/>
        <Route path="/code/exercise-tracker/" exact component={ExercisesList} />
        <Route path="/code/exercise-tracker/edit/:id" component={EditExercise} /> 
        <Route path="/code/exercise-tracker/create" component={CreateExercise} />
        <Route path="/code/exercise-tracker/user" exact component={CreateUser} />
        </div>
        </Router>
        </React.Fragment>
    );
}