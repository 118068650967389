const initialState = {
  squares: Array(9).fill(null),
  xIsNext: true,
  historySquares: Array(9).fill(null),
};

const tictactoe = (state = initialState, action) => {
  switch (action.type) {
    case 'add_xo':
      const squares = state.historySquares.slice();
      // console.log(squares);
      squares[action.id] = state.xIsNext ? 'X' : 'O';
      // console.log(squares);
      const historySquares = state.historySquares.slice();

      return {
        ...state,
        squares,
        xIsNext: state.xIsNext,
        historySquares,
      };

    case 'confirm_move':
      const squares_1 = state.squares.slice();
      const historySquares_1 = state.squares.slice();

      return {
        ...state,
        squares: squares_1,
        xIsNext: !state.xIsNext,
        historySquares: historySquares_1,
      };

    case 'reset_move':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default tictactoe;
