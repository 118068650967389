import React from 'react';
import { Link } from 'react-router-dom';
import s from './style';

function TopNav(props) {
  return (
    <s.SideBarNav>
      <s.Header>Checkout my app(updated)</s.Header>
      <s.List>
        <s.ListItem><Link to="/">Dashboard</Link></s.ListItem>
        <s.ListItem><Link to="/tictactoe">Tic tac toe</Link></s.ListItem>
        <s.ListItem><Link to="/todo">To Do List</Link></s.ListItem>
        <s.ListItem><Link to="/settings">Settings</Link></s.ListItem>
      </s.List>
    </s.SideBarNav>
  );
}

export default TopNav;
