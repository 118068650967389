import React, { Component } from 'react';
import Board from './Board';
import s from './style';

class TicTacToe extends Component {
  render() {
    return (
      <s.game>
        <s.game_board>
          <Board />
        </s.game_board>
      </s.game>
    );
  }
}

export default TicTacToe;
