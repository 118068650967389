import React from 'react';

export default function DialoguesClub(){
    return(
        <>
        <div className="container bg-light">
            <h1>Dialogues Club</h1>
            <ul className="list-group">
                <li className="list-group-item bg-info">
                    Non-sense
                    
                </li>
                <li className="list-group-item">
                    My Dear Jijaji
                    
                </li>
                <li className="list-group-item">
                    Babumusay....zindagi to ek rang manch ki katputliya hai
                </li>
            </ul>
        </div>
        </>
    );
}