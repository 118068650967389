import React from 'react';


function Tech(){
    return (
        <React.Fragment>
            <ul className="menutop">
                <li>PHP</li>
                <li>MySQL</li>
                <li>Apache</li>
                <li>MongoDB</li>
                <li>WordPress</li>
                <li>Node JS</li>
                <li>React JS</li>
            </ul>
        </React.Fragment>
    );
}

export default Tech;