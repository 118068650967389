import React, { Component } from 'react';

class EoiConnect extends Component {

  render() {
    return (
      	<div>        		
        	<button >Connect</button>     
    	</div>
     )
  }
}

export default EoiConnect;
