import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import TopNav from './partials/LeftNav';
import ToDoList from './pages/ToDoList';
import TicTacToe from './pages/TicTacToe';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import Abpage from './pages/Abpage';

class App extends Component {
  constructor() {
    super();
    this.state = {
      pagetime: `${0}:${0}:${0}`,
    };
  }

  // componentDidMount() {
  //   const pagehours = 0;
  //   let pageminutes = 0;
  //   let pageseconds = 0;

  //   setInterval(() => {
  //     if (pageseconds >= 59) {
  //       pageminutes++;
  //       pageseconds = 0;
  //     } else {
  //       pageseconds++;
  //     }
  //     // const pagetime = `${pagehours}:${pageminutes}:${pageseconds}`;
  //     // this.setState({ pagetime });
  //   }, 1000);
  // }

  render() {
    return (
      <Router>
        <div className="App">
          <div>
            Time on site : {this.state.pagetime}
            <TopNav />
          </div>

          <div>
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/todo" exact component={ToDoList} />
              <Route path="/tictactoe" exact component={TicTacToe} />
              <Route path="/settings" exact component={Settings} />
              <Route path="/ab" exact component={Abpage} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
