import React, { PureComponent } from 'react';
import axios from 'axios';
import t from 'tcomb-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addPerson } from '../../actions';
// import s from './style';

const Form = t.form.Form;

const Gender = t.enums({
  M: 'Male',
  F: 'Female',
});

const City = t.enums({
  Mumbai: 'Mumbai',
  Thane: 'Thane',
});

// const options = {
//   fields: {
//     gender: {
//       options: [
//         { value: 'M', text: 'Maschio' },
//         // use `disabled: true` to disable an option
//         { value: 'F', text: 'Femmina' },
//       ],
//     },
//   },
// };

/* const Person = t.struct({
  name: t.String,
  bio: t.maybe(t.String),
  city: City,
  gender: Gender // enum
});*/

const Person = {
  name: t.String,
  bio: t.maybe(t.String),
  city: City,
  gender: Gender, // enum
};

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.state = { value: {}, type: t.struct(Person), options: {} };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    axios
      .get('https://www.whizapi.com/api/v2/util/ui/in/indian-city-by-state?project-app-key=lmaarljdnxim3c58takjixro&stateid=3')
      .then(response => {
        const cities = response.data.Data.map(locationData => locationData.city);
        this.setState({
          type: t.struct({ ...Person, city: t.enums.of(cities) }),
        });
      });
  }

  onChange() {
    const value = this.refs.form.getValue(); 
    if (value) {
      this.setState({ value });
    }
  }

  save() {
    const value = this.refs.form.getValue();
    console.log(2222, value, this.refs.form.validate());
    if (value) {
      const currentData = { name: value.name, bio: value.bio, city: value.city, gender: value.gender };
      this.props.addPerson(currentData);
    }
  }

  render() {
    console.log('render', this.state.value);
    return (
      <div>
        <Form ref="form" type={this.state.type} value={this.state.value} options={this.state.options} onChange={this.onChange}  />
        <button onClick={this.save}>Save</button>
      </div>
    );
  }
}

const st = {};

function mapStateToProps(state) {
  return st;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ addPerson }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Settings);
