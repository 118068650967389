const initialToDo = {
  todo_0: [],
  todo_1: [],
};

const todos = (state = initialToDo, action) => {
  // console.log(action);
  switch (action.type) {
    case 'ADD_TODO_0':
      // console.log(state.todo_0);
      // console.log(action.text);
      return {
        ...state,
        todo_0: state.todo_0.concat({
          id: action.id,
          text: action.text,
          completed: false,
        }),
      };

    case 'ADD_TODO_1':
      // console.log(state.todo_1);
      // console.log(action.text);
      return {
        ...state,
        todo_1: state.todo_1.concat({
          id: action.id,
          text: action.text,
          completed: false,
        }),
      };

    default:
      return state;
  }
};

export default todos;
