import React from 'react';

export default function FooterPartials(){

    return(
        <div className="container">
            <footer className="page-footer font-small blue pt-4">
                <p>Disclaimer: This site was developed by me in this lockdown for training and learning purpose</p>
            </footer>
        </div>
    );
}