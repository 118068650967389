import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../PartialPages/NavbarPartial';

export default function Code(){
    return (
        <React.Fragment>
            <div className="container">
            <Navbar />
            <h3>List of code</h3>
            <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <Link to="/code/exercise-tracker" className="nav-link">Excercise Project</Link>
                </li>
                <li>WP Fun</li>
                <li>WP React</li>
                <li><Link to="/code/to-do" className="nav-link">To Do App</Link></li>
                <li><Link to="/code/simple-weather-app" className="nav-link">Weather App</Link></li>
                <li>Tic Tac Toe</li>
                <li><Link to="/code/movie-app" className="nav-link">Movie App</Link></li>
x               <li>Weather App using PWA</li>
                <li>Some other apps</li>
            </ul>
            </div>
        </React.Fragment>
    );

}