import React from 'react';
// import { connect } from 'react-redux';
import InputList from './InputList';
import ShowList from './ShowList';
// import { addTodo } from './actions'
import s from './style';

const ToDoList = () =>
  <s.ToDoListContainer>
    <s.Header>
      Welcome to React - To Do List
    </s.Header>
    <s.InputListContainer>
      <InputList />
    </s.InputListContainer>

    <s.ShowListContainer>
      <ShowList />
    </s.ShowListContainer>
  </s.ToDoListContainer>;

export default ToDoList;
