import React, { Component } from 'react';

class EoiInterest extends Component {
  
  render() {
    return (
      	<div>        		
        	<button >Interest</button>     
    	</div>
     )
  }
}

export default EoiInterest;
